import React from "react";
import CountUp from "react-countup";

const getMetrics = (liveCCU, totalVisits) => [
  {
    amount: "21K",
    label: "Peak Concurrent Users",
    style: "bg-gradient-to-r from-pink-500 to-purple-500",
  },
  {
    amount: "72M",
    label: "Play Sessions",
    style: "bg-[#1a1a1a]",
  },
  {
    amount: totalVisits,
    label: "Visits",
    style: "bg-[#1a1a1a]",
  },
  {
    amount: liveCCU,
    label: "Total Live Players",
    style: "bg-gradient-to-r from-green-500 to-blue-500",
  },
];

function useAnimatedNumber(targetValue, duration = 1500) {
  const [displayValue, setDisplayValue] = React.useState(targetValue);

  React.useEffect(() => {
    let start = parseInt(displayValue.replace(/,/g, ""), 10) || 0;
    let end = parseInt(targetValue.replace(/,/g, ""), 10) || 0;
    let startTime = null;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const current = Math.floor(start + (end - start) * progress);

      setDisplayValue(current.toLocaleString());

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }, [targetValue, duration]);

  return displayValue;
}

function MetricCard({ amount, label, style }) {
  var animatedValue = useAnimatedNumber(amount, 1500);

  if (amount == "21K" || amount == "72M") {
     animatedValue = amount
  }

  return (
    <div className={`${style} rounded-md shadow-md p-6 flex flex-col items-center text-white`}>
      <span className="text-2xl font-bold">{animatedValue}</span>
      <span className="text-sm text-gray-200 mt-1">{label}</span>
    </div>
  );
}

const MetricsSection = ({ liveCCU, totalVisits }) => {
  const metrics = getMetrics(liveCCU, totalVisits);

  // Simple countdown state & effect
  const [timeLeft, setTimeLeft] = React.useState(3);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prev) => {
        // when it hits 1, jump back to 3
        return prev === 1 ? 3 : prev - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="bg-black py-24" id="metrics">
      <div className="container mx-auto px-4 text-center">
        <p className="border border-white/20 w-fit mx-auto px-3 py-1 rounded-full text-white text-sm uppercase tracking-wider mb-4">
          Metrics
        </p>
        <h2 className="text-4xl md:text-5xl font-extrabold text-white mb-4">
          Our Metrics
        </h2>
        <p className="text-lg font-light text-gray-300 w-full md:w-8/12 mx-auto mb-12">
          We've worked across a number of genres and achieved incredible results
          with our games. In just three months of operation, we reached these
          impressive milestones:
        </p>

        <div className="max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 mt-3">
          {metrics.map((metric, index) => (
            <MetricCard
              key={index}
              amount={metric.amount}
              label={metric.label}
              style={metric.style}
            />
          ))}
        </div>

        {/* Another note if you want it under the cards */}
        <p className="text-sm text-gray-500 mt-6">
          This gets refreshed in {timeLeft}…
        </p>
      </div>
    </section>
  );
};

export default MetricsSection;
