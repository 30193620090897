import React from "react";
import logo from "../logo2.png";
// Import icons from React Icons (Font Awesome)
import { FaTwitter, FaYoutube, FaDiscord } from "react-icons/fa";

const navItems = [
  {
    href: "#app",
    label: <img src={logo} alt="Sparkle Logo" className="h-8 md:h-10 invert" />,
    className: "hidden md:block"
  },
  { href: "#team", label: "Team" },
  { href: "#metrics", label: "Metrics" },
  { href: "#games", label: "Games" },
  {
    href: "#contact",
    label: "Contact us",
    className:
      "ml-2 md:ml-4 bg-hero-gradient hover:saturate-150 text-white py-2 px-3 md:py-2 md:px-4 rounded-lg transition-all text-center hidden md:block"
  }
];

// Add Discord to the social items
const socialItems = [
  {
    href: "https://twitter.com/playSparkle  ",
    label: <FaTwitter className="text-xl" />,
    tooltip: "Follow us on X (Twitter)"
  },
  {
    href: "https://www.youtube.com/@playSparkle",
    label: <FaYoutube className="text-xl" />,
    tooltip: "Watch us on YouTube"
  },
  {
    href: "https://discord.gg/z4qsYPGWHr",
    label: <FaDiscord className="text-xl" />,
    tooltip: "Join us on Discord"
  }
];

const Navbar = () => {
  return (
    <nav className="text-white p-5 flex justify-center items-center max-w-7xl mx-auto sticky top-0 z-[1000]">
      <div className="flex items-center space-x-3 md:space-x-5 border border-[#232323] bg-black py-2 px-4 rounded-xl text-base">
        {/* Main navigation items */}
        {navItems.map((item, index) => (
          <a
            key={index}
            href={item.href}
            className={`hover:bg-[#232323] p-2 px-3 rounded-lg transition text-gray-200 ${
              item.className || ""
            }`}
          >
            {item.label}
          </a>
        ))}

        {/* Divider (Optional) */}
        <span className="hidden md:inline-block w-px h-6 bg-gray-600 mx-2" />

        {/* Social Media Links */}
        {socialItems.map((item, index) => (
          <a
            key={index}
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:bg-[#232323] p-2 px-3 rounded-lg transition text-gray-200"
            title={item.tooltip}
          >
            {item.label}
          </a>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
