import React, { useState } from "react";
import emailjs from "emailjs-com";
import { motion } from "framer-motion";

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.from_name) errors.from_name = "Name is required";
    if (!formData.reply_to) {
      errors.reply_to = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.reply_to)) {
      errors.reply_to = "Email is invalid";
    }
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.message) errors.message = "Message is required";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    emailjs
      .send(
        "service_rsba7gc",
        "template_9tjafyn",
        formData,
        "zxJWw1e5GGPh0f1a3"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setNotification({
            type: "success",
            message: "Your message has been sent successfully!",
          });
          setFormData({
            from_name: "",
            reply_to: "",
            subject: "",
            message: "",
          });
          setErrors({});
          setLoading(false);
        },
        (error) => {
          console.log("FAILED...", error);
          setNotification({
            type: "error",
            message: "Failed to send your message. Please try again.",
          });
          setLoading(false);
        }
      );
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center px-4 bg-black"
      id="contact"
    >
      {/* Subtle animated gradient background */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-[#090909] to-black opacity-90" />

      <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
        className="relative w-full max-w-2xl bg-[#0c0c0c] p-8 rounded-lg shadow-2xl z-10"
      >
        <motion.h2
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          className="text-4xl font-semibold text-white text-center mb-6"
        >
          Contact Us
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.6 }}
          className="text-gray-300 mb-8 text-center leading-relaxed"
        >
          We’d love to hear from you! Whether you have questions, feedback, or
          inquiries about acquisitions, brand partnerships, or other business
          opportunities, please fill out the form below. We’ll get back to you
          as soon as possible.
        </motion.p>

        {notification.message && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className={`text-center mb-4 p-2 rounded ${
              notification.type === "success"
                ? "bg-green-500 text-white"
                : "bg-red-500 text-white"
            }`}
          >
            {notification.message}
          </motion.div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Name Field */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            viewport={{ once: true }}
          >
            <label className="block text-sm font-medium text-gray-300">
              Name
            </label>
            <div className="relative">
              <input
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                  errors.from_name ? "border-red-500" : "border-gray-600"
                } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition`}
                placeholder="Your Name"
                required
              />
              {errors.from_name && (
                <p className="text-red-500 text-sm mt-1">{errors.from_name}</p>
              )}
            </div>
          </motion.div>

          {/* Email Field */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.15, duration: 0.5 }}
            viewport={{ once: true }}
          >
            <label className="block text-sm font-medium text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="reply_to"
              value={formData.reply_to}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.reply_to ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition`}
              placeholder="you@example.com"
              required
            />
            {errors.reply_to && (
              <p className="text-red-500 text-sm mt-1">{errors.reply_to}</p>
            )}
          </motion.div>

          {/* Subject Field */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            viewport={{ once: true }}
          >
            <label className="block text-sm font-medium text-gray-300">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.subject ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition`}
              placeholder="What's on your mind?"
              required
            />
            {errors.subject && (
              <p className="text-red-500 text-sm mt-1">{errors.subject}</p>
            )}
          </motion.div>

          {/* Message Field */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.25, duration: 0.5 }}
            viewport={{ once: true }}
          >
            <label className="block text-sm font-medium text-gray-300">
              Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.message ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition`}
              rows="5"
              placeholder="Your message..."
              required
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">{errors.message}</p>
            )}
          </motion.div>

          {/* Submit Button */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            viewport={{ once: true }}
          >
            <button
              type="submit"
              className={`w-full py-3 bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-400 hover:to-pink-400 text-white font-semibold rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-200 flex items-center justify-center ${
                loading ? "opacity-50 cursor-not-allowed" : "hover:scale-105"
              }`}
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              )}
              {loading ? "Sending..." : "Send Message"}
            </button>
          </motion.div>
        </form>
      </motion.div>
    </div>
  );
};

export default Contact;
