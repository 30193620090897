// components/Games.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import GameCarousel from "./utils/GameCarousel"; // Import the GameCarousel component
import GameGrid from "./utils/GameGrid"; // Import the GameGrid component
const Games = ({ setLiveCCU, settotalVisits }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let intervalId;

    const fetchGames = async () => {
      try {
        const response = await axios.get("https://utopia.sparkle.gg/public/games-list");
        setGames(response.data.games);
        setLoading(false);

        const totalLiveCCU = response.data.games.reduce((acc, game) => acc + game.playing, 0);
        const totalVisitsz = response.data.games.reduce((acc, game) => acc + game.visits, 0);

        setLiveCCU(totalLiveCCU.toLocaleString());
        settotalVisits(totalVisitsz.toLocaleString());
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    // Fetch data once on mount
    fetchGames();

    // Poll the data every 5 seconds
    intervalId = setInterval(fetchGames, 5000);

    // Clear the interval on unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [])

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading games: {error.message}</p>;
  }

  return (
    <section className="bg-black py-32 pb-24" id="games">
      <div className="container mx-auto px-4 text-center">
        <p className="border border-white/20 w-fit mx-auto px-3 py-1 rounded-full text-white text-sm uppercase tracking-wider mb-4">
          Games
        </p>
        <h2 className="text-4xl md:text-6xl font-black mt-4 text-white">
          Our Games
        </h2>
        <p className="text-md md:text-lg mt-6 font-light text-gray-300 lg:w-6/12 w-10/12 mx-auto pb-12">
          At Sparkle, our mission is to explore every genre to captivate and
          entertain our players. We intend to conquer every genre.
        </p>

        <GameGrid games={games} />
        {/* Teaser Section */}
        <div className="mt-12 max-w-xl mx-auto text-center">
          <p className="text-lg md:text-xl font-light text-gray-300 italic">
            More games are coming soon...
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Stay updated by following us on our socials!
          </p>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-6">
            <a
              href="https://twitter.com/playSparkle"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-2 text-white rounded-md bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-400 hover:to-blue-500 transition-all duration-200 w-full sm:w-auto text-center"
            >
              Follow on Twitter
            </a>

            <a
              href="https://discord.gg/z4qsYPGWHr"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-2 text-white rounded-md bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-400 hover:to-pink-400 transition-all duration-200 w-full sm:w-auto text-center"
            >
              Join our Discord
            </a>
          </div>
        </div>
      </div>
    </section>
  );

};

export default Games;
