import React from "react";

const GameGrid = ({ games }) => {
  // Sort games based on the 'playing' property in descending order
  const sortedGames = [...games].sort((a, b) => b.playing - a.playing);

  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else {
      return num.toString();
    }
  }

  return (
    <div className="p-6 bg-black ">
      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedGames.map((game, index) => (
          <div
            key={index}
            className="bg-[#0c0c0c] p-4 rounded-lg transition-transform hover:shadow-lg hover:scale-[1.02]"
          >
            <div className="relative overflow-hidden rounded-lg mb-4">
              <img
                src={game.thumbnailUrl}
                alt={game.name}
                className="w-full h-48 object-cover transition-transform duration-200 hover:scale-105 rounded-lg"
              />
              <div className="absolute top-2 left-2 bg-black/70 text-white text-xs px-2 py-1 rounded-sm">
                ⭐ {formatNumber(game.favoritedCount)}
              </div>
            </div>

            <div className="text-white text-left space-y-1">
              <h2 className="font-bold text-lg md:text-xl truncate">{game.name}</h2>
              <p className="text-sm text-gray-300">
                {formatNumber(game.visits)} visits &#8226; {game.genre}
              </p>
              <p className="text-sm text-gray-300">
                🔴 {formatNumber(game.playing)} playing
              </p>
            </div>

            <a
              href={`https://www.roblox.com/games/${game.rootPlaceId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-4 px-5 py-2 text-center text-white bg-blue-600 rounded-md hover:bg-blue-500 transition"
            >
              View Game
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameGrid;
