import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const RippleButton = ({ children, className, href }) => {
  const handleRipple = (event) => {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.getBoundingClientRect().left - radius
      }px`;
    circle.style.top = `${event.clientY - button.getBoundingClientRect().top - radius
      }px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) ripple.remove();

    button.appendChild(circle);

    setTimeout(() => {
      circle.remove();
    }, 600);
  };

  const Tag = href ? "a" : "button";

  return (
    <Tag
      className={`relative overflow-hidden ${className}`}
      onClick={handleRipple}
      href={href}
      target="_blank"
    >
      {children}
      <style jsx>{`
        .ripple {
          position: absolute;
          border-radius: 50%;
          transform: scale(0);
          animation: ripple 600ms linear;
          background-color: rgba(255, 255, 255, 0.7);
        }
        @keyframes ripple {
          to {
            transform: scale(4);
            opacity: 0;
          }
        }
      `}</style>
    </Tag>
  );
};

const handleScrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const teamMembers = [
  { name: "strawhatvert", role: "cap n cook", userid: 3810754327 },
  { name: "teabled", role: "heisenberg", userid: 2366538947 },
  { name: "tafzri", role: "game producer", userid: 1057895133 },
  { name: "avwzz", role: "game producer", userid: 941184424 },
  { name: "reachify", role: "game producer", userid: 87145254 },
  { name: "headr0ws", role: "game producer", userid: 55499399 },
  { name: "9hoste", role: "game producer", userid: 1104258874 },
  { name: "Vortbloxian", role: "game producer", userid: 142362222 },
];

const TeamMemberCard = ({ name, role, image, userid }) => {
  const isOwner = name.toLowerCase() === "strawhatvert";

  return (
    <div className={`flex flex-col items-center text-center group transition duration-200 ${isOwner ? "glitch-owner" : ""}`}>
      <a
        href={`https://www.roblox.com/users/${userid}`}
        target="_blank"
        rel="noopener noreferrer"
        className="group"
      >
        <div className="w-24 h-24 md:w-32 md:h-32 lg:w-36 lg:h-36 rounded-full overflow-hidden border-2 border-white/20 group-hover:border-white transition duration-200 shadow-md">
          <img
            src={image}
            alt={name}
            className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-200"
          />
        </div>
        <h3
          className={`text-white mt-3 text-lg font-semibold ${isOwner ? "glitch-text relative inline-block" : ""
            }`}
          data-text={name}
        >
          {name}
        </h3>
        <p className="text-gray-400 text-sm">{role}</p>
      </a>
      <style jsx>{`
        /* Glitch effect only applies if parent has glitch-owner class and we hover over it */
        .glitch-owner .glitch-text {
          position: relative;
        }
        .glitch-owner:hover .glitch-text::before,
        .glitch-owner:hover .glitch-text::after {
          content: attr(data-text);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          overflow: hidden;
          color: #0ff; /* Cyan shade */
        }

        /* Top layer (offset and clipped) */
        .glitch-owner:hover .glitch-text::before {
          animation: glitchTop 1s infinite steps(2, start);
          clip: rect(0, 9999px, 0, 0);
        }

        /* Bottom layer (offset and clipped) */
        .glitch-owner:hover .glitch-text::after {
          animation: glitchBot 1s infinite steps(2, start);
          clip: rect(0, 9999px, 0, 0);
          color: #f0f; /* Magenta shade */
        }

        @keyframes glitchTop {
          0% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
          20% {
            clip: rect(5px, 9999px, 30px, 0);
            transform: translate(-5px, -5px);
          }
          40% {
            clip: rect(10px, 9999px, 5px, 0);
            transform: translate(5px, 5px);
          }
          60% {
            clip: rect(2px, 9999px, 25px, 0);
            transform: translate(-5px, 2px);
          }
          80% {
            clip: rect(15px, 9999px, 5px, 0);
            transform: translate(5px, -2px);
          }
          100% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
        }

        @keyframes glitchBot {
          0% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
          20% {
            clip: rect(10px, 9999px, 20px, 0);
            transform: translate(5px, -5px);
          }
          40% {
            clip: rect(5px, 9999px, 30px, 0);
            transform: translate(-5px, 5px);
          }
          60% {
            clip: rect(10px, 9999px, 10px, 0);
            transform: translate(2px, -5px);
          }
          80% {
            clip: rect(0, 9999px, 15px, 0);
            transform: translate(-2px, 5px);
          }
          100% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
        }
      `}</style>
    </div>
  );
};

const Who = () => {
  const [team, setTeam] = useState(teamMembers);

  useEffect(() => {
    const fetchImages = async () => {
      const updatedTeam = await Promise.all(
        teamMembers.map(async (member) => {
          const response = await fetch(
            `https://api.allorigins.win/get?url=${encodeURIComponent(
              `https://thumbnails.roblox.com/v1/users/avatar-headshot?userIds=${member.userid}&size=420x420&format=Png&isCircular=false`
            )}`
          );
          const data = await response.json();
          const parsedData = JSON.parse(data.contents);
          const imageUrl = parsedData.data[0]?.imageUrl || member.image;
          return { ...member, image: imageUrl };
        })
      );
      setTeam(updatedTeam);
    };

    fetchImages();
  }, []);

  return (
    <section className="bg-black py-20" id="team">
      <div className="container mx-auto px-4 text-center">
        <p className="border border-white/20 w-fit mx-auto px-3 py-1 rounded-full text-white text-sm uppercase tracking-wider mb-4">
          Team
        </p>
        <h2 className="text-4xl md:text-5xl font-black text-white">
          Meet the Team
        </h2>
        <p className="text-md md:text-lg mt-4 font-light text-gray-300 max-w-xl mx-auto">
          We are an independently owned game studio founded and operated by two
          passionate creators. Each member of our team brings a unique
          perspective, resulting in a diverse and dynamic experience.
        </p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 mt-12 justify-items-center"
        >
          {team.map((member, i) => (
            <TeamMemberCard
              key={i}
              name={member.name}
              role={member.role}
              image={member.image}
              userid={member.userid}
            />
          ))}
        </motion.div>
      </div>

      <div className="container mx-auto px-4 text-center max-w-xl mt-16">
        <blockquote className="text-xl md:text-2xl font-medium text-white italic">
          "Here at Sparkle, we focus on quality. We're dedicated to creating the best games on the ROBLOX platform. We're passionate about delivering experiences that are both immersive and engaging."
        </blockquote>
        <p className="text-white text-sm mt-2">- strawhatvert</p>

        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-8">
          <RippleButton
            className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-400 hover:to-pink-400 text-white font-medium py-3 px-8 rounded-md transition-all duration-200 w-full sm:w-auto"
            onClick={() => handleScrollTo("contact")}
          >
            Send us a message
          </RippleButton>

          <RippleButton
            href="https://discord.gg/z4qsYPGWHr" 
            target
            className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-400 hover:to-blue-500 text-white font-medium py-3 px-8 rounded-md transition-all duration-200 w-full sm:w-auto"
          >
            Join our Discord
          </RippleButton>
        </div>
      </div>

    </section>
  );
};

export default Who;