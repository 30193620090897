import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Who from "./components/Who";
import MetricsSection from "./components/Metrics";
import Games from "./components/Games";
import Contact from "./components/Contact";
import AcquisitionPage from "./components/Acquisition";

import { useState } from "react";
function App() {
  const [liveCCU, setLiveCCU] = useState("0");
  const [totalVisits, settotalVisits] = useState("0");

  return (
    <div className="App bg-black" id="app">
      <Navbar />
      <Hero />
      <Who />
      <MetricsSection liveCCU={liveCCU} totalVisits={totalVisits} />
      <Games setLiveCCU={setLiveCCU} settotalVisits={settotalVisits} />
      {/* <AcquisitionPage /> */}
      <Contact />
    </div>
  );
}

export default App;
